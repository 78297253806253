import React from 'react';
import { Helmet } from 'react-helmet';
import CatholicPrayersSkill from '../images/catholic-prayers-skill.png';
import CatholicPrayersASMRSkill from '../images/catholic-prayers-asmr-skill.png';
import DoorbellSoundsSkill from '../images/ring-doorbell-skill.png';
import WakeUpSkill from '../images/wake-up-skill.png';
import WakeUpASMRSkill from '../images/wake-up-asmr-skill.png';
import EaglesChantSkill from '../images/eagles-chant-skill.png';
import MostFamousPrincessBrideQuoteSkill from '../images/most-famous-princess-bride-quote-skill.png';
import TitanicSkill from '../images/titanic-skill.png';
import CleanupSkill from '../images/cleanup-game-skill.png';
import TheUglyBarnacleSkill from '../images/the-ugly-barnacle-skill.png';
import ChristmasGuitarSkill from '../images/christmas-guitar-skill.png';
import CowSoundsSkill from '../images/cow-sounds-skill.png';
import ElephantSoundsSkill from '../images/elephant-sounds-skill.png';
import RomeoAndJulietQuotesSkill from '../images/romeo-and-juliet-quotes-skill.png';
import HamletQuotesSkill from '../images/hamlet-quotes-skill.png';
import ItalySightsSkill from '../images/italy-sights-skill.png';
import LetsGoCampingSkill from '../images/lets-go-camping-skill.png';
import UnofficialEasyButtonSkill from '../images/unofficial-easy-button-skill.png';
import DeerSoundsSkill from '../images/deer-sounds-skill.png';
import BurpSoundsSkill from '../images/burp-sounds-skill.png';
import KnockOnDoorSkill from '../images/knock-on-door-skill.png';
import BeepHornSkill from '../images/beep-horn-skill.png';
import BaroqueFactsSkill from '../images/baroque-facts-skill.png';
import ColorBarsAndToneSkill from '../images/color-bars-and-tone-skill.png';
import UnitedStates from '../images/us.png';
import Canada from '../images/canada.png';
import UnitedKingdom from '../images/uk.png';
import Australia from '../images/australia.png';
import Mexico from '../images/mexico.png';
import Spain from '../images/spain.png';
import Brazil from '../images/brazil.png';
import France from '../images/france.png';
import Italy from '../images/italy.png';
import Germany from '../images/germany.png';
import Japan from '../images/japan.png';
import India from '../images/india.png';
import ContactCTA from './ContactCTA';

const AlexaSkillsUnitedKingdom = () => {
    return (
        <div className="AlexaSkillsUnitedKingdom">
   <div className="container content">
      <Helmet>
         <title>Alexa Skills in United Kingdom - Nick Azzarello</title>
         <meta name="description" content="Nick Azzarello has created Alexa Skills for recreational purposes. He has over twenty skills that are available on Amazon in the United Kingdom." />
         <meta name="keywords" content="nick azzarello, nickopanther, alexa skills, amazon alexa" />
      </Helmet>
      <h1 className="center title">Alexa Skills in the United Kingdom</h1>
      <p className="center">A list of the many Alexa Skills I have created that are available to use
      on any Alexa device. Click on an image, or name, to learn more about a skill.</p>
      <div className="center">
        <img src={UnitedKingdom}
             height="135"
             width="240"
             alt="United Kingdom"
             title="United Kingdom"
         />
      </div>
      <div className="row one">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.14a9a072-6159-4902-ba45-b65310cefc72/launch" target="_blank" rel="noopener noreferrer">
               <img src={CatholicPrayersSkill}
                    height="210"
                    width="210"
                    alt="Catholic Prayers"
                    title="Catholic Prayers"
                />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.14a9a072-6159-4902-ba45-b65310cefc72/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Catholic Prayers</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.ee383748-2737-4144-bc23-874329ddda96/launch" target="_blank" rel="noopener noreferrer">
               <img src={DoorbellSoundsSkill}
                  height="210"
                  width="210"
                  alt="Doorbell Sounds"
                  title="Doorbell Sounds" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.ee383748-2737-4144-bc23-874329ddda96/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Doorbell Sounds</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.1d857b1f-601a-4b79-a429-aae6c1486ff2/launch" target="_blank" rel="noopener noreferrer">
               <img src={WakeUpSkill}
                  height="210"
                  width="210"
                  alt="Wake Up"
                  title="Wake Up" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.1d857b1f-601a-4b79-a429-aae6c1486ff2/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Wake Up</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row two">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.f665cb70-b65a-4f9f-b736-c5e6a95657d0/launch" target="_blank" rel="noopener noreferrer">
               <img src={EaglesChantSkill}
                  height="210"
                  width="210"
                  alt="Eagles Chant"
                  title="Eagles Chant" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.f665cb70-b65a-4f9f-b736-c5e6a95657d0/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Eagles Chant</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.c68a67f0-fb5a-4314-b2c3-c6b2e42e1d45/launch" target="_blank" rel="noopener noreferrer">
               <img src={MostFamousPrincessBrideQuoteSkill}
                    height="210"
                    width="210"
                    alt="Most Famous Princess Bride Quote"
                    title="Most Famous Princess Bride Quote" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.c68a67f0-fb5a-4314-b2c3-c6b2e42e1d45/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Most Famous Princess Bride Quote</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.f579673c-fab6-4ba0-86d3-c562e70610ad/launch" target="_blank" rel="noopener noreferrer">
               <img src={TitanicSkill}
                    height="210"
                    width="210"
                    alt="Titanic (1912) vs Titan (1898) Facts"
                    title="Titanic (1912) vs Titan (1898) Facts"
                />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.f579673c-fab6-4ba0-86d3-c562e70610ad/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Titanic (1912) vs Titan (1898) Facts</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row three">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.edb4e1b6-0814-4384-b810-098b80a8c42f/launch" target="_blank" rel="noopener noreferrer">
               <img src={CleanupSkill}
                    alt="Cleanup Game"
                    title="Cleanup Game"
                    height="210"
                    width="210"
                />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.edb4e1b6-0814-4384-b810-098b80a8c42f/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Cleanup Game</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.f128dfc9-78f6-43eb-b62c-254c77db4b9e/launch" target="_blank" rel="noopener noreferrer">
               <img src={TheUglyBarnacleSkill}
                    height="210"
                    width="210"
                    alt="The Ugly Barnacle"
                    title="The Ugly Barnacle"
                />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.f128dfc9-78f6-43eb-b62c-254c77db4b9e/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">The Ugly Barnacle</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.90000caf-ecf5-414c-9721-49d1fcf64fa7/launch" target="_blank" rel="noopener noreferrer">
               <img src={ChristmasGuitarSkill}
                  height="210"
                  width="210"
                  alt="Christmas Guitar"
                  title="Christmas Guitar" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.90000caf-ecf5-414c-9721-49d1fcf64fa7/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Christmas Guitar</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row four">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.ef28c002-c648-4d1f-afc9-ffce96fc195c/launch" target="_blank" rel="noopener noreferrer">
               <img src={CatholicPrayersASMRSkill}
                  height="210"
                  width="210"
                  title="Catholic Prayers (ASMR Edition)"
                  alt="Catholic Prayers (ASMR Edition)" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.ef28c002-c648-4d1f-afc9-ffce96fc195c/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Catholic Prayers (ASMR Edition)</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.51ec9ac6-c284-484c-a65b-7a08c86cd78f/launch" target="_blank" rel="noopener noreferrer">
               <img src={BeepHornSkill}
                  height="210"
                  width="210"
                  alt="Beep Horn"
                  title="Beep Horn" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.51ec9ac6-c284-484c-a65b-7a08c86cd78f/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Beep Horn</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.8b94abfe-bec3-4368-a242-860d8180aa1b/launch" target="_blank" rel="noopener noreferrer">
               <img src={WakeUpASMRSkill}
                  height="210"
                  width="210"
                  title="Wake Up (ASMR Edition)"
                  alt="Wake Up (ASMR Edition)" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.8b94abfe-bec3-4368-a242-860d8180aa1b/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Wake Up (ASMR Edition)</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row five">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.4173293a-fb28-410c-89c5-de658c7a66a6/launch" target="_blank" rel="noopener noreferrer">
               <img src={KnockOnDoorSkill}
                  height="210"
                  width="210"
                  title="Knock on Door"
                  alt="Knock on Door" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.4173293a-fb28-410c-89c5-de658c7a66a6/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Knock on Door</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.b0be6877-dec7-4659-9c39-c0cda6c83765/launch" target="_blank" rel="noopener noreferrer">
               <img src={ColorBarsAndToneSkill}
                  height="210"
                  width="210"
                  alt="Color Bars and Tone"
                  title="Color Bars and Tone" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.b0be6877-dec7-4659-9c39-c0cda6c83765/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Color Bars and Tone</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.0d04e96b-ffd2-4fe3-9a26-a1a8869b88a3/launch" target="_blank" rel="noopener noreferrer">
               <img src={BaroqueFactsSkill}
                  height="210"
                  width="210"
                  title="Baroque Facts"
                  alt="Baroque Facts" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.0d04e96b-ffd2-4fe3-9a26-a1a8869b88a3/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Baroque Facts</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row six">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.317a238a-a2cb-4fc4-a17d-96b104d90311/launch" target="_blank" rel="noopener noreferrer">
               <img src={ItalySightsSkill}
                  height="210"
                  width="210"
                  title="Italy Sights"
                  alt="Italy Sights" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.317a238a-a2cb-4fc4-a17d-96b104d90311/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Italy Sights</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.4ab74cd3-2c53-4e35-bd97-39f461bd352c/launch" target="_blank" rel="noopener noreferrer">
               <img src={HamletQuotesSkill}
                  height="210"
                  width="210"
                  alt="Hamlet Quotes"
                  title="Hamlet Quotes" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.4ab74cd3-2c53-4e35-bd97-39f461bd352c/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Hamlet Quotes</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.35d64df0-5519-4f94-9a86-317c16564b72/launch" target="_blank" rel="noopener noreferrer">
               <img src={RomeoAndJulietQuotesSkill}
                  height="210"
                  width="210"
                  title="Romeo and Juliet Quotes"
                  alt="Romeo and Juliet Quotes" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.35d64df0-5519-4f94-9a86-317c16564b72/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Romeo and Juliet Quotes</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row seven">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.b5fb2b86-7b2c-47ed-ab23-7defa830517a/launch" target="_blank" rel="noopener noreferrer">
               <img src={LetsGoCampingSkill}
                  height="210"
                  width="210"
                  alt="Let's Go Camping"
                  title="Let's Go Camping" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.b5fb2b86-7b2c-47ed-ab23-7defa830517a/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Let's Go Camping</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.113340bb-2f0c-4fd1-b5a4-273ca3e8d1f3/launch" target="_blank" rel="noopener noreferrer">
               <img src={ElephantSoundsSkill}
                  height="210"
                  width="210"
                  alt="Elephant Sounds"
                  title="Elephant Sounds" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.113340bb-2f0c-4fd1-b5a4-273ca3e8d1f3/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Elephant Sounds</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.6be1dca2-0b34-49dd-9134-6e9dd6916c15/launch" target="_blank" rel="noopener noreferrer">
               <img src={CowSoundsSkill}
                  height="210"
                  width="210"
                  title="Cow Sounds"
                  alt="Cow Sounds" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.6be1dca2-0b34-49dd-9134-6e9dd6916c15/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Cow Sounds</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row eight">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.b141e581-6067-4ec8-9171-32a52e44d36e/launch" target="_blank" rel="noopener noreferrer">
               <img src={UnofficialEasyButtonSkill}
                  height="210"
                  width="210"
                  alt="Unofficial Easy Button"
                  title="Unofficial Easy Button" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.b141e581-6067-4ec8-9171-32a52e44d36e/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Unofficial Easy Button</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.a98be640-3f8e-47a7-a739-5cc75dba05f5/launch" target="_blank" rel="noopener noreferrer">
               <img src={BurpSoundsSkill}
                  height="210"
                  width="210"
                  alt="Burp Sounds"
                  title="Burp Sounds" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.a98be640-3f8e-47a7-a739-5cc75dba05f5/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Burp Sounds</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.47ee85d4-d6bd-4699-bf3f-2bd26cf79488/launch" target="_blank" rel="noopener noreferrer">
               <img src={DeerSoundsSkill}
                  height="210"
                  width="210"
                  title="Deer Sounds"
                  alt="Deer Sounds" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.uk/apis/custom/skills/amzn1.ask.skill.47ee85d4-d6bd-4699-bf3f-2bd26cf79488/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Deer Sounds</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="container">
        <h2 className="center title">Alexa Skills in other Countries</h2>
        <p className="center">If you live outside of the United Kingdom and are interested in one of my
        Alexa Skills, click on the respective flag from your country to see what
        is available.</p>

        <div className="row one">
           <div className="alexa col s12 m3 l3">
              <div className="flag-image">
                 <a href="/alexa-skills" rel="noopener noreferrer">
                 <img src={UnitedStates}
                      height="135"
                      width="240"
                      alt="United States"
                      title="United States"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m3 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-canada" rel="noopener noreferrer">
                 <img src={Canada}
                      height="135"
                      width="240"
                      alt="Canada"
                      title="Canada"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m3 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-australia" rel="noopener noreferrer">
                 <img src={Australia}
                      height="135"
                      width="240"
                      alt="Australia"
                      title="Australia"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m3 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-mexico" rel="noopener noreferrer">
                 <img src={Mexico}
                      height="135"
                      width="240"
                      alt="Mexico"
                      title="Mexico"
                  />
                 </a>
              </div>
           </div>
        </div>
        <div className="row two">
           <div className="alexa col s12 m3 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-spain" rel="noopener noreferrer">
                 <img src={Spain}
                      height="135"
                      width="240"
                      alt="Spain"
                      title="Spain"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m3 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-brazil" rel="noopener noreferrer">
                 <img src={Brazil}
                      height="135"
                      width="240"
                      alt="Brazil"
                      title="Brazil"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m3 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-france" rel="noopener noreferrer">
                 <img src={France}
                      height="135"
                      width="240"
                      alt="France"
                      title="France"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m3 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-germany" rel="noopener noreferrer">
                 <img src={Germany}
                      height="135"
                      width="240"
                      alt="Germany"
                      title="Germany"
                  />
                 </a>
              </div>
           </div>
        </div>
        <div className="row three">
           <div className="alexa col s12 m3 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-italy" rel="noopener noreferrer">
                 <img src={Italy}
                      height="135"
                      width="240"
                      alt="Italy"
                      title="Italy"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m3 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-japan" rel="noopener noreferrer">
                 <img src={Japan}
                      height="135"
                      width="240"
                      alt="Japan"
                      title="Japan"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m3 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-india" rel="noopener noreferrer">
                 <img src={India}
                      height="135"
                      width="240"
                      alt="India"
                      title="India"
                  />
                 </a>
              </div>
           </div>
        </div>
      </div>
   </div>
   <ContactCTA />
</div>
    )
}

export default AlexaSkillsUnitedKingdom;
