import React from 'react';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import HeroImage from '../images/hero-image.jpg';
import CatholicPrayersSlide from '../images/catholic-prayers-slide.jpg';
import RingDoorbellSlide from '../images/ring-doorbell-slide.jpg';
import BaroqueFactsSlide from '../images/baroque-facts-slide.jpg';
import TestImage from '../images/test.jpg';

/*
    Resource for slider
    https://www.npmjs.com/package/react-responsive-carousel

    Dimensions for images on desktop:
    1920 x 510
*/

const Slideshow = () => {
    return (
        <div>

            <Carousel>
                <div>
                    <img src={CatholicPrayersSlide}
                    alt="Catholic Prayers skill slide"
                    />
                    <div className="slider-content">
                        <h1>Catholic Prayers</h1>
                        <div className="content-width">
                        <p>Have your Alexa say a prayer for you under your choice or you can ask it to pick a prayer for you.</p>
                        </div>
                        <a href="https://alexa-skills.amazon.com/apis/custom/skills/amzn1.ask.skill.14a9a072-6159-4902-ba45-b65310cefc72/launch" target="_blank" rel="noopener noreferrer" className="white-text text-lighten-3">
                            <button className="btn-large green">
                            Learn More
                        </button>
                        </a>
                    </div>
                </div>
                <div>
                    <img src={RingDoorbellSlide}
                    alt="Ring Doorbell skill slide"
                    />
                    <div className="slider-content">
                        <h1>Ring Doorbell</h1>
                        <p>Have Alexa ring the doorbell for you.</p>
                        <a href="https://alexa-skills.amazon.com/apis/custom/skills/amzn1.ask.skill.b26fd58b-6244-4518-8a30-56c83d46f9fa/launch" target="_blank" rel="noopener noreferrer" className="white-text text-lighten-3">
                            <button className="btn-large green">
                            Learn More
                        </button>
                        </a>
                    </div>
                </div>
                <div>
                    <img src={BaroqueFactsSlide}
                    alt="Baroque Facts skill slide"
                     />
                    <div className="slider-content">
                        <h1>Baroque Facts</h1>
                        <div className="content-width">
                        <p>Learn the most fascinating facts about the Baroque era. 
                        You will learn about the art and the music that helped make this era history.</p>
                        </div>
                        <a href="https://alexa-skills.amazon.com/apis/custom/skills/amzn1.ask.skill.0d04e96b-ffd2-4fe3-9a26-a1a8869b88a3/launch" target="_blank" rel="noopener noreferrer" className="white-text text-lighten-3">
                            <button className="btn-large green">
                            Learn More
                        </button>
                        </a>
                    </div>
                </div>
            </Carousel>

        </div>
    )
}

export default Slideshow;
