import React from 'react';
import { Helmet } from 'react-helmet';
import CatholicPrayersSkill from '../images/catholic-prayers-skill.png';
import CatholicPrayersASMRSkill from '../images/catholic-prayers-asmr-skill-spanish.png';
import DoorbellSoundsSkill from '../images/ring-doorbell-skill.png';
import WakeUpSkill from '../images/wake-up-skill.png';
import WakeUpASMRSkill from '../images/wake-up-asmr-skill-spanish.png';
import MostFamousPrincessBrideQuoteSkill from '../images/most-famous-princess-bride-quote-skill.png';
import CleanupSkill from '../images/cleanup-game-skill.png';
import CowSoundsSkill from '../images/cow-sounds-skill.png';
import ElephantSoundsSkill from '../images/elephant-sounds-skill.png';
import LetsGoCampingSkill from '../images/lets-go-camping-skill.png';
import UnofficialEasyButtonSkill from '../images/unofficial-easy-button-skill-spanish.png';
import DeerSoundsSkill from '../images/deer-sounds-skill.png';
import BurpSoundsSkill from '../images/burp-sounds-skill-spanish.png';
import AirplaneSoundsSkill from '../images/airplane-sounds-skill.png';
import HelicopterSoundsSkill from '../images/helicopter-sounds-skill.png';
import KnockOnDoorSkill from '../images/knock-on-door-skill.png';
import MonkeySoundsSkill from '../images/monkey-sounds-skill.png';
import BeepHornSkill from '../images/beep-horn-skill.png';
import UnitedStates from '../images/us.png';
import Canada from '../images/canada.png';
import UnitedKingdom from '../images/uk.png';
import Australia from '../images/australia.png';
import Mexico from '../images/mexico.png';
import Spain from '../images/spain.png';
import Brazil from '../images/brazil.png';
import France from '../images/france.png';
import Italy from '../images/italy.png';
import Germany from '../images/germany.png';
import Japan from '../images/japan.png';
import India from '../images/india.png';
import ContactCTA from './ContactCTA';

const AlexaSkillsMexico = () => {
    return (
        <div className="AlexaSkillsMexico">
   <div className="container content">
      <Helmet>
         <title>Alexa Skills in Mexico - Nick Azzarello</title>
         <meta name="description" content="Nick Azzarello has created Alexa Skills for recreational purposes. He has over twenty skills that are available on Amazon." />
         <meta name="keywords" content="nick azzarello, nickopanther, alexa skills, amazon alexa" />
      </Helmet>
      <h1 className="center title">Alexa Skills in Mexico</h1>
      <p className="center">A list of the many Alexa Skills I have created that are available to use
      on any Alexa device. Click on an image, or name, to learn more about a skill.</p>
      <hr />
      <h1 className="center title">Alexa Skills en México</h1>
      <p className="center">Una lista de las muchas de Alexa Skills que he creado que están disponibles para usar
      en cualquier dispositivo Alexa. Haga clic en una imagen o nombre para obtener más información
      sobre una skill.</p>
      <div className="center">
        <img src={Mexico}
             height="135"
             width="240"
             alt="Mexico"
             title="Mexico"
         />
      </div>
      <div className="row one">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.14a9a072-6159-4902-ba45-b65310cefc72/launch" target="_blank" rel="noopener noreferrer">
               <img src={CatholicPrayersSkill}
                    height="210"
                    width="210"
                    alt="Oraciones Católicas"
                    title="Oraciones Católicas"
                />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.14a9a072-6159-4902-ba45-b65310cefc72/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Oraciones Católicas</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.ee383748-2737-4144-bc23-874329ddda96/launch" target="_blank" rel="noopener noreferrer">
               <img src={DoorbellSoundsSkill}
                  height="210"
                  width="210"
                  alt="Sonidos de timbre"
                  title="Sonidos de timbre" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.ee383748-2737-4144-bc23-874329ddda96/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Sonidos de timbre</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.1d857b1f-601a-4b79-a429-aae6c1486ff2/launch" target="_blank" rel="noopener noreferrer">
               <img src={WakeUpSkill}
                  height="210"
                  width="210"
                  alt="Despierta"
                  title="Despierta" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.1d857b1f-601a-4b79-a429-aae6c1486ff2/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Despierta</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row two">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
              <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.4173293a-fb28-410c-89c5-de658c7a66a6/launch" target="_blank" rel="noopener noreferrer">
              <img src={KnockOnDoorSkill}
                  height="210"
                  width="210"
                  alt="Llama a la Puerta"
                  title="Llama a la Puerta" />
              </a>
            </div>
            <div className="alexa-content">
              <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.4173293a-fb28-410c-89c5-de658c7a66a6/launch" target="_blank" rel="noopener noreferrer">
                <h3 className="center title indigo-text text-darken-4">Llama a la Puerta</h3>
              </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.c68a67f0-fb5a-4314-b2c3-c6b2e42e1d45/launch" target="_blank" rel="noopener noreferrer">
               <img src={MostFamousPrincessBrideQuoteSkill}
                    height="210"
                    width="210"
                    alt="Cita de la princesa prometida más famosa"
                    title="Cita de la princesa prometida más famosa" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.c68a67f0-fb5a-4314-b2c3-c6b2e42e1d45/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Cita de la princesa prometida más famosa</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
              <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.c6be4126-0a73-4006-b694-6bd1a323a4e1/launch" target="_blank" rel="noopener noreferrer">
              <img src={MonkeySoundsSkill}
                   height="210"
                   width="210"
                   alt="Sonidos de Mono"
                   title="Sonidos de Mono" />
              </a>
            </div>
            <div className="alexa-content">
              <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.c6be4126-0a73-4006-b694-6bd1a323a4e1/launch" target="_blank" rel="noopener noreferrer">
               <h3 className="center title indigo-text text-darken-4">Sonidos de Mono</h3>
              </a>
            </div>
         </div>
      </div>
      <div className="row three">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.edb4e1b6-0814-4384-b810-098b80a8c42f/launch" target="_blank" rel="noopener noreferrer">
               <img src={CleanupSkill}
                    alt="Juego de Limpieza"
                    title="Juego de Limpieza"
                    height="210"
                    width="210"
                />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.edb4e1b6-0814-4384-b810-098b80a8c42f/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Juego de Limpieza</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
              <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.bf9cf4a7-0b6c-47d5-90eb-10d719bb7523/launch" target="_blank" rel="noopener noreferrer">
              <img src={AirplaneSoundsSkill}
                 alt="Sonidos de aviones"
                 title="Sonidos de aviones"
                 height="210"
                 width="210"
              />
              </a>
            </div>
            <div className="alexa-content">
              <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.bf9cf4a7-0b6c-47d5-90eb-10d719bb7523/launch" target="_blank" rel="noopener noreferrer">
                <h3 className="center title indigo-text text-darken-4">Sonidos de aviones</h3>
              </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
              <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.2047fc04-6821-4470-a5d7-ed13d7b89157/launch" target="_blank" rel="noopener noreferrer">
                <img src={HelicopterSoundsSkill}
                     alt="Sonidos de Helicóptero"
                     title="Sonidos de Helicóptero"
                     height="210"
                     width="210"
                />
              </a>
            </div>
            <div className="alexa-content">
              <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.2047fc04-6821-4470-a5d7-ed13d7b89157/launch" target="_blank" rel="noopener noreferrer">
              <h3 className="center title indigo-text text-darken-4">Sonidos de Helicóptero</h3>
            </a>
          </div>
         </div>
      </div>
      <div className="row four">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.ef28c002-c648-4d1f-afc9-ffce96fc195c/launch" target="_blank" rel="noopener noreferrer">
               <img src={CatholicPrayersASMRSkill}
                  height="210"
                  width="210"
                  title="Oraciones Católicas (Edición RSAM)"
                  alt="Oraciones Católicas (Edición RSAM)" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.ef28c002-c648-4d1f-afc9-ffce96fc195c/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Oraciones Católicas (Edición RSAM)</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.51ec9ac6-c284-484c-a65b-7a08c86cd78f/launch" target="_blank" rel="noopener noreferrer">
               <img src={BeepHornSkill}
                  height="210"
                  width="210"
                  alt="Bocina de Coche"
                  title="Bocina de Coche" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.51ec9ac6-c284-484c-a65b-7a08c86cd78f/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Bocina de Coche</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.8b94abfe-bec3-4368-a242-860d8180aa1b/launch" target="_blank" rel="noopener noreferrer">
               <img src={WakeUpASMRSkill}
                  height="210"
                  width="210"
                  title="Despierta (Edición RSAM)"
                  alt="Despierta (Edición RSAM)" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.8b94abfe-bec3-4368-a242-860d8180aa1b/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Despierta (Edición RSAM)</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row five">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.b5fb2b86-7b2c-47ed-ab23-7defa830517a/launch" target="_blank" rel="noopener noreferrer">
               <img src={LetsGoCampingSkill}
                  height="210"
                  width="210"
                  alt="Vamos a acampar"
                  title="Vamos a acampar" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.b5fb2b86-7b2c-47ed-ab23-7defa830517a/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Vamos a acampar</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.113340bb-2f0c-4fd1-b5a4-273ca3e8d1f3/launch" target="_blank" rel="noopener noreferrer">
               <img src={ElephantSoundsSkill}
                  height="210"
                  width="210"
                  alt="Sonidos de elefante"
                  title="Sonidos de elefante" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.113340bb-2f0c-4fd1-b5a4-273ca3e8d1f3/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Sonidos de elefante</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.6be1dca2-0b34-49dd-9134-6e9dd6916c15/launch" target="_blank" rel="noopener noreferrer">
               <img src={CowSoundsSkill}
                  height="210"
                  width="210"
                  title="Sonidos de Vaca"
                  alt="Sonidos de Vaca" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.6be1dca2-0b34-49dd-9134-6e9dd6916c15/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Sonidos de Vaca</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row six">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.b141e581-6067-4ec8-9171-32a52e44d36e/launch" target="_blank" rel="noopener noreferrer">
               <img src={UnofficialEasyButtonSkill}
                  height="210"
                  width="210"
                  alt="Botón fácil no oficial"
                  title="Botón fácil no oficial" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.b141e581-6067-4ec8-9171-32a52e44d36e/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Botón fácil no oficial</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.a98be640-3f8e-47a7-a739-5cc75dba05f5/launch" target="_blank" rel="noopener noreferrer">
               <img src={BurpSoundsSkill}
                  height="210"
                  width="210"
                  alt="Sonidos de Eructos"
                  title="Sonidos de Eructos" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.a98be640-3f8e-47a7-a739-5cc75dba05f5/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Sonidos de Eructos</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.47ee85d4-d6bd-4699-bf3f-2bd26cf79488/launch" target="_blank" rel="noopener noreferrer">
               <img src={DeerSoundsSkill}
                  height="210"
                  width="210"
                  title="Sonidos de ciervo"
                  alt="Sonidos de ciervo" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.mx/apis/custom/skills/amzn1.ask.skill.47ee85d4-d6bd-4699-bf3f-2bd26cf79488/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Sonidos de ciervo</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="container">
        <h2 className="center title">Alexa Skills in other Countries</h2>
        <p className="center">If you live outside of Mexico and are interested in one of my
        Alexa Skills, click on the respective flag from your country to see what
        is available.</p>

        <div className="row one">
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills" rel="noopener noreferrer">
                 <img src={UnitedStates}
                      height="135"
                      width="240"
                      alt="United States"
                      title="United States"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-canada" rel="noopener noreferrer">
                 <img src={Canada}
                      height="135"
                      width="240"
                      alt="Canada"
                      title="Canada"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-united-kingdom" rel="noopener noreferrer">
                 <img src={UnitedKingdom}
                      height="135"
                      width="240"
                      alt="United Kingdom"
                      title="United Kingdom"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                <a href="/alexa-skills-australia" rel="noopener noreferrer">
                  <img src={Australia}
                       height="135"
                       width="240"
                       alt="Australia"
                       title="Australia"
                  />
                </a>
              </div>
           </div>
        </div>
        <div className="row two">
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-spain" rel="noopener noreferrer">
                 <img src={Spain}
                      height="135"
                      width="240"
                      alt="Spain"
                      title="Spain"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-brazil" rel="noopener noreferrer">
                 <img src={Brazil}
                      height="135"
                      width="240"
                      alt="Brazil"
                      title="Brazil"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-france" rel="noopener noreferrer">
                 <img src={France}
                      height="135"
                      width="240"
                      alt="France"
                      title="France"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-germany" rel="noopener noreferrer">
                 <img src={Germany}
                      height="135"
                      width="240"
                      alt="Germany"
                      title="Germany"
                  />
                 </a>
              </div>
           </div>
        </div>
        <div className="row three">
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-italy" rel="noopener noreferrer">
                 <img src={Italy}
                      height="135"
                      width="240"
                      alt="Italy"
                      title="Italy"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-japan" rel="noopener noreferrer">
                 <img src={Japan}
                      height="135"
                      width="240"
                      alt="Japan"
                      title="Japan"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-india" rel="noopener noreferrer">
                 <img src={India}
                      height="135"
                      width="240"
                      alt="India"
                      title="India"
                  />
                 </a>
              </div>
           </div>
        </div>
      </div>
   </div>
   <ContactCTA />
</div>
    )
}

export default AlexaSkillsMexico;
