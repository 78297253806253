import React from 'react';
import { Helmet } from 'react-helmet';
import PigNoisesSkill from '../images/pig-noises-skill.png'
import TrainSoundsSkill from '../images/train-sounds-skill.png';
import HowlingDogsSkill from '../images/howling-dogs-skill.png';
import CleanupSkill from '../images/cleanup-game-skill.png';
import CowSoundsSkill from '../images/cow-sounds-skill.png';
import SquirrelSoundsSkill from '../images/squirrel-sounds-skill.png';
import CatSoundsSkill from '../images/cat-sounds-skill.png';
import UnofficialEasyButtonSkill from '../images/unofficial-easy-button-skill-japanese.png';
import BurpSoundsSkill from '../images/burp-sounds-skill-japanese.png';
import AirplaneSoundsSkill from '../images/airplane-sounds-skill.png';
import HelicopterSoundsSkill from '../images/helicopter-sounds-skill.png';
import KnockOnDoorSkill from '../images/knock-on-door-skill.png';
import BeepHornSkill from '../images/beep-horn-skill.png';
import UnitedStates from '../images/us.png';
import Canada from '../images/canada.png';
import UnitedKingdom from '../images/uk.png';
import Australia from '../images/australia.png';
import Mexico from '../images/mexico.png';
import Spain from '../images/spain.png';
import Brazil from '../images/brazil.png';
import France from '../images/france.png';
import Italy from '../images/italy.png';
import Germany from '../images/germany.png';
import Japan from '../images/japan.png';
import India from '../images/india.png';
import ContactCTA from './ContactCTA';

const AlexaSkillsJapan = () => {
    return (
        <div className="AlexaSkillsJapan">
   <div className="container content">
      <Helmet>
         <title>Alexa Skills in Japan - Nick Azzarello</title>
         <meta name="description" content="Nick Azzarello has created Alexa Skills for recreational purposes. He has over twenty skills that are available on Amazon." />
         <meta name="keywords" content="nick azzarello, nickopanther, alexa skills, amazon alexa" />
      </Helmet>
      <h1 className="center title">Alexa Skills in Japan</h1>
      <p className="center">A list of the many Alexa Skills I have created that are available to use
      on any Alexa device. Click on an image, or name, to learn more about a skill.</p>
      <hr />
      <h1 className="center title">日本のアレクサスキル</h1>
      <p className="center">使用できる、私が作成した多くのAlexaスキルのリスト
      任意のAlexaデバイス。スキルの詳細については、画像または名前をクリックしてください。</p>
      <div className="center">
        <img src={Japan}
             height="135"
             width="240"
             alt="Japan"
             title="Japan"
         />
      </div>
      <div className="row one">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.16d4362f-ab1e-459a-9d78-ac341f93a9c4/launch" target="_blank" rel="noopener noreferrer">
               <img src={PigNoisesSkill}
                    height="210"
                    width="210"
                    alt="豚の異音"
                    title="豚の異音"
                />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.16d4362f-ab1e-459a-9d78-ac341f93a9c4/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">豚の異音</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.3c0df3e8-d80e-45f1-a15c-e7683459d8c0/launch" target="_blank" rel="noopener noreferrer">
               <img src={TrainSoundsSkill}
                  height="210"
                  width="210"
                  alt="電車の音"
                  title="電車の音" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.3c0df3e8-d80e-45f1-a15c-e7683459d8c0/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">電車の音</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.a98be640-3f8e-47a7-a739-5cc75dba05f5/launch" target="_blank" rel="noopener noreferrer">
               <img src={BurpSoundsSkill}
                  height="210"
                  width="210"
                  alt="げっぷ音"
                  title="げっぷ音" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.a98be640-3f8e-47a7-a739-5cc75dba05f5/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">げっぷ音</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row two">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
              <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.4173293a-fb28-410c-89c5-de658c7a66a6/launch" target="_blank" rel="noopener noreferrer">
              <img src={KnockOnDoorSkill}
                  height="210"
                  width="210"
                  alt="ドアをノック"
                  title="ドアをノック" />
              </a>
            </div>
            <div className="alexa-content">
              <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.4173293a-fb28-410c-89c5-de658c7a66a6/launch" target="_blank" rel="noopener noreferrer">
                <h3 className="center title indigo-text text-darken-4">ドアをノック</h3>
              </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.e4a86281-2a84-4769-ba61-134b012a1dbd/launch" target="_blank" rel="noopener noreferrer">
               <img src={HowlingDogsSkill}
                    height="210"
                    width="210"
                    alt="ハウリング犬"
                    title="ハウリング犬" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.e4a86281-2a84-4769-ba61-134b012a1dbd/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">ハウリング犬</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
              <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.51ec9ac6-c284-484c-a65b-7a08c86cd78f/launch" target="_blank" rel="noopener noreferrer">
              <img src={BeepHornSkill}
                   height="210"
                   width="210"
                   alt="ビープホーン"
                   title="ビープホーン" />
              </a>
            </div>
            <div className="alexa-content">
              <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.51ec9ac6-c284-484c-a65b-7a08c86cd78f/launch" target="_blank" rel="noopener noreferrer">
               <h3 className="center title indigo-text text-darken-4">ビープホーン</h3>
              </a>
            </div>
         </div>
      </div>
      <div className="row three">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.edb4e1b6-0814-4384-b810-098b80a8c42f/launch" target="_blank" rel="noopener noreferrer">
               <img src={CleanupSkill}
                    alt="クリーンアップゲーム"
                    title="クリーンアップゲーム"
                    height="210"
                    width="210"
                />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.edb4e1b6-0814-4384-b810-098b80a8c42f/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">クリーンアップゲーム</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
              <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.bf9cf4a7-0b6c-47d5-90eb-10d719bb7523/launch" target="_blank" rel="noopener noreferrer">
              <img src={AirplaneSoundsSkill}
                 alt="飛行機の音"
                 title="飛行機の音"
                 height="210"
                 width="210"
              />
              </a>
            </div>
            <div className="alexa-content">
              <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.bf9cf4a7-0b6c-47d5-90eb-10d719bb7523/launch" target="_blank" rel="noopener noreferrer">
                <h3 className="center title indigo-text text-darken-4">飛行機の音</h3>
              </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
              <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.2047fc04-6821-4470-a5d7-ed13d7b89157/launch" target="_blank" rel="noopener noreferrer">
                <img src={HelicopterSoundsSkill}
                     alt="ヘリコプターの音"
                     title="ヘリコプターの音"
                     height="210"
                     width="210"
                />
              </a>
            </div>
            <div className="alexa-content">
              <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.2047fc04-6821-4470-a5d7-ed13d7b89157/launch" target="_blank" rel="noopener noreferrer">
              <h3 className="center title indigo-text text-darken-4">ヘリコプターの音</h3>
            </a>
          </div>
         </div>
      </div>
      <div className="row four">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.ba7b8f9d-11bf-4b8d-84b8-16eb57399f37/launch" target="_blank" rel="noopener noreferrer">
               <img src={CatSoundsSkill}
                  height="210"
                  width="210"
                  alt="猫の音"
                  title="猫の音" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.ba7b8f9d-11bf-4b8d-84b8-16eb57399f37/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">猫の音</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.c5611ac4-1b91-46a3-b5ac-0de7fd9094a5/launch" target="_blank" rel="noopener noreferrer">
               <img src={SquirrelSoundsSkill}
                  height="210"
                  width="210"
                  alt="リスの音"
                  title="リスの音" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.c5611ac4-1b91-46a3-b5ac-0de7fd9094a5/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">リスの音</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.6be1dca2-0b34-49dd-9134-6e9dd6916c15/launch" target="_blank" rel="noopener noreferrer">
               <img src={CowSoundsSkill}
                  height="210"
                  width="210"
                  title="牛の音"
                  alt="牛の音" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.6be1dca2-0b34-49dd-9134-6e9dd6916c15/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">牛の音</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row five">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.b141e581-6067-4ec8-9171-32a52e44d36e/launch" target="_blank" rel="noopener noreferrer">
               <img src={UnofficialEasyButtonSkill}
                  height="210"
                  width="210"
                  alt="非公式の簡単なボタン"
                  title="非公式の簡単なボタン" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.co.jp/apis/custom/skills/amzn1.ask.skill.b141e581-6067-4ec8-9171-32a52e44d36e/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">非公式の簡単なボタン</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="container">
        <h2 className="center title">Alexa Skills in other Countries</h2>
        <p className="center">If you live outside of Japan and are interested in one of my
        Alexa Skills, click on the respective flag from your country to see what
        is available.</p>

        <div className="row one">
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills" rel="noopener noreferrer">
                 <img src={UnitedStates}
                      height="135"
                      width="240"
                      alt="United States"
                      title="United States"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-canada" rel="noopener noreferrer">
                 <img src={Canada}
                      height="135"
                      width="240"
                      alt="Canada"
                      title="Canada"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-united-kingdom" rel="noopener noreferrer">
                 <img src={UnitedKingdom}
                      height="135"
                      width="240"
                      alt="United Kingdom"
                      title="United Kingdom"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                <a href="/alexa-skills-australia" rel="noopener noreferrer">
                  <img src={Australia}
                       height="135"
                       width="240"
                       alt="Australia"
                       title="Australia"
                  />
                </a>
              </div>
           </div>
        </div>
        <div className="row two">
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-mexico" rel="noopener noreferrer">
                 <img src={Mexico}
                      height="135"
                      width="240"
                      alt="Mexico"
                      title="Mexico"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-spain" rel="noopener noreferrer">
                 <img src={Spain}
                      height="135"
                      width="240"
                      alt="Spain"
                      title="Spain"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-brazil" rel="noopener noreferrer">
                 <img src={Brazil}
                      height="135"
                      width="240"
                      alt="Brazil"
                      title="Brazil"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-france" rel="noopener noreferrer">
                 <img src={France}
                      height="135"
                      width="240"
                      alt="France"
                      title="France"
                  />
                 </a>
              </div>
           </div>
        </div>
        <div className="row three">
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-germany" rel="noopener noreferrer">
                 <img src={Germany}
                      height="135"
                      width="240"
                      alt="Germany"
                      title="Germany"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-italy" rel="noopener noreferrer">
                 <img src={Italy}
                      height="135"
                      width="240"
                      alt="Italy"
                      title="Italy"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-india" rel="noopener noreferrer">
                 <img src={India}
                      height="135"
                      width="240"
                      alt="India"
                      title="India"
                  />
                 </a>
              </div>
           </div>
        </div>
      </div>
   </div>
   <ContactCTA />
</div>
    )
}

export default AlexaSkillsJapan;
