import React from 'react';
import { Helmet } from 'react-helmet';
import CatholicPrayersSkill from '../images/catholic-prayers-skill.png';
import CatholicPrayersASMRSkill from '../images/catholic-prayers-asmr-skill-portuguese.png';
import DoorbellSoundsSkill from '../images/ring-doorbell-skill.png';
import WakeUpSkill from '../images/wake-up-skill.png';
import WakeUpASMRSkill from '../images/wake-up-asmr-skill-portuguese.png';
import HowlingDogsSkill from '../images/howling-dogs-skill.png';
import CleanupSkill from '../images/cleanup-game-skill.png';
import CowSoundsSkill from '../images/cow-sounds-skill.png';
import ElephantSoundsSkill from '../images/elephant-sounds-skill.png';
import LetsGoCampingSkill from '../images/lets-go-camping-skill.png';
import UnofficialEasyButtonSkill from '../images/unofficial-easy-button-skill-portuguese.png';
import DeerSoundsSkill from '../images/deer-sounds-skill.png';
import BurpSoundsSkill from '../images/burp-sounds-skill-portuguese.png';
import AirplaneSoundsSkill from '../images/airplane-sounds-skill.png';
import HelicopterSoundsSkill from '../images/helicopter-sounds-skill.png';
import KnockOnDoorSkill from '../images/knock-on-door-skill.png';
import MonkeySoundsSkill from '../images/monkey-sounds-skill.png';
import BeepHornSkill from '../images/beep-horn-skill.png';
import UnitedStates from '../images/us.png';
import Canada from '../images/canada.png';
import UnitedKingdom from '../images/uk.png';
import Australia from '../images/australia.png';
import Mexico from '../images/mexico.png';
import Spain from '../images/spain.png';
import Brazil from '../images/brazil.png';
import France from '../images/france.png';
import Italy from '../images/italy.png';
import Germany from '../images/germany.png';
import Japan from '../images/japan.png';
import India from '../images/india.png';
import ContactCTA from './ContactCTA';

const AlexaSkillsBrazil = () => {
    return (
        <div className="AlexaSkillsBrazil">
   <div className="container content">
      <Helmet>
         <title>Alexa Skills in Brazil - Nick Azzarello</title>
         <meta name="description" content="Nick Azzarello has created Alexa Skills for recreational purposes. He has over twenty skills that are available in Brazil on Amazon." />
         <meta name="keywords" content="nick azzarello, nickopanther, alexa skills, amazon alexa" />
      </Helmet>
      <h1 className="center title">Alexa Skills in Brazil</h1>
      <p className="center">A list of the many Alexa Skills I have created that are available to use
      on any Alexa device. Click on an image, or name, to learn more about a skill.</p>
      <hr />
      <h1 className="center title">Alexa Skills no Brasil</h1>
      <p className="center">Uma lista das muitas Alexa Skills que criei e que estão disponíveis para uso
      em qualquer dispositivo Alexa. Clique em uma imagem ou nome para aprender mais sobre
      uma skill.</p>
      <div className="center">
        <img src={Brazil}
             height="135"
             width="240"
             alt="Brazil"
             title="Brazil"
        />
      </div>
      <div className="row one">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.14a9a072-6159-4902-ba45-b65310cefc72/launch" target="_blank" rel="noopener noreferrer">
               <img src={CatholicPrayersSkill}
                    height="210"
                    width="210"
                    alt="Orações Católicas"
                    title="Orações Católicas"
                />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.14a9a072-6159-4902-ba45-b65310cefc72/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Orações Católicas</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.ee383748-2737-4144-bc23-874329ddda96/launch" target="_blank" rel="noopener noreferrer">
               <img src={DoorbellSoundsSkill}
                  height="210"
                  width="210"
                  alt="Sons de campainha"
                  title="Sons de campainha" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.ee383748-2737-4144-bc23-874329ddda96/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Sons de campainha</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.1d857b1f-601a-4b79-a429-aae6c1486ff2/launch" target="_blank" rel="noopener noreferrer">
               <img src={WakeUpSkill}
                  height="210"
                  width="210"
                  alt="Acorda Jogo"
                  title="Acorda Jogo" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.1d857b1f-601a-4b79-a429-aae6c1486ff2/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Acorda Jogo</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row two">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
              <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.4173293a-fb28-410c-89c5-de658c7a66a6/launch" target="_blank" rel="noopener noreferrer">
              <img src={KnockOnDoorSkill}
                  height="210"
                  width="210"
                  alt="Bata na Porta"
                  title="Bata na Porta" />
              </a>
            </div>
            <div className="alexa-content">
              <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.4173293a-fb28-410c-89c5-de658c7a66a6/launch" target="_blank" rel="noopener noreferrer">
                <h3 className="center title indigo-text text-darken-4">Bata na Porta</h3>
              </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.e4a86281-2a84-4769-ba61-134b012a1dbd/launch" target="_blank" rel="noopener noreferrer">
               <img src={HowlingDogsSkill}
                    height="210"
                    width="210"
                    alt="Cães uivando"
                    title="Cães uivando" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.e4a86281-2a84-4769-ba61-134b012a1dbd/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Cães uivando</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
              <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.c6be4126-0a73-4006-b694-6bd1a323a4e1/launch" target="_blank" rel="noopener noreferrer">
              <img src={MonkeySoundsSkill}
                   height="210"
                   width="210"
                   alt="Sons de Macaco"
                   title="Sons de Macaco" />
              </a>
            </div>
            <div className="alexa-content">
              <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.c6be4126-0a73-4006-b694-6bd1a323a4e1/launch" target="_blank" rel="noopener noreferrer">
               <h3 className="center title indigo-text text-darken-4">Sons de Macaco</h3>
              </a>
            </div>
         </div>
      </div>
      <div className="row three">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.edb4e1b6-0814-4384-b810-098b80a8c42f/launch" target="_blank" rel="noopener noreferrer">
               <img src={CleanupSkill}
                    alt="Jogo de Limpeza"
                    title="Jogo de Limpeza"
                    height="210"
                    width="210"
                />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.edb4e1b6-0814-4384-b810-098b80a8c42f/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Jogo de Limpeza</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
              <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.bf9cf4a7-0b6c-47d5-90eb-10d719bb7523/launch" target="_blank" rel="noopener noreferrer">
              <img src={AirplaneSoundsSkill}
                 alt="Sons de Avião"
                 title="Sons de Avião"
                 width="210"
                 height="210"
              />
              </a>
            </div>
            <div className="alexa-content">
              <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.bf9cf4a7-0b6c-47d5-90eb-10d719bb7523/launch" target="_blank" rel="noopener noreferrer">
                <h3 className="center title indigo-text text-darken-4">Sons de Avião</h3>
              </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
              <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.2047fc04-6821-4470-a5d7-ed13d7b89157/launch" target="_blank" rel="noopener noreferrer">
                <img src={HelicopterSoundsSkill}
                     alt="Sons de helicóptero"
                     title="Sons de helicóptero"
                     height="210"
                     width="210"
                />
              </a>
            </div>
            <div className="alexa-content">
              <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.2047fc04-6821-4470-a5d7-ed13d7b89157/launch" target="_blank" rel="noopener noreferrer">
              <h3 className="center title indigo-text text-darken-4">Sons de helicóptero</h3>
            </a>
          </div>
         </div>
      </div>
      <div className="row four">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.ef28c002-c648-4d1f-afc9-ffce96fc195c/launch" target="_blank" rel="noopener noreferrer">
               <img src={CatholicPrayersASMRSkill}
                  height="210"
                  width="210"
                  title="Orações Católicas (Edição RSAM)"
                  alt="Orações Católicas (Edição RSAM)" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.ef28c002-c648-4d1f-afc9-ffce96fc195c/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Orações Católicas (Edição RSAM)</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.51ec9ac6-c284-484c-a65b-7a08c86cd78f/launch" target="_blank" rel="noopener noreferrer">
               <img src={BeepHornSkill}
                  height="210"
                  width="210"
                  alt="Buzina de Carro"
                  title="Buzina de Carro" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.51ec9ac6-c284-484c-a65b-7a08c86cd78f/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Buzina de Carro</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.8b94abfe-bec3-4368-a242-860d8180aa1b/launch" target="_blank" rel="noopener noreferrer">
               <img src={WakeUpASMRSkill}
                  height="210"
                  width="210"
                  title="Acorde (Edição RSAM)"
                  alt="Acorde (Edição RSAM)" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.8b94abfe-bec3-4368-a242-860d8180aa1b/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Acorde (Edição RSAM)</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row five">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.b5fb2b86-7b2c-47ed-ab23-7defa830517a/launch" target="_blank" rel="noopener noreferrer">
               <img src={LetsGoCampingSkill}
                  height="210"
                  width="210"
                  alt="Vamos acampar"
                  title="Vamos acampar" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.b5fb2b86-7b2c-47ed-ab23-7defa830517a/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Vamos acampar</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.113340bb-2f0c-4fd1-b5a4-273ca3e8d1f3/launch" target="_blank" rel="noopener noreferrer">
               <img src={ElephantSoundsSkill}
                  height="210"
                  width="210"
                  alt="Sons de elefante"
                  title="Sons de elefante" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.113340bb-2f0c-4fd1-b5a4-273ca3e8d1f3/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Sons de elefante</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.6be1dca2-0b34-49dd-9134-6e9dd6916c15/launch" target="_blank" rel="noopener noreferrer">
               <img src={CowSoundsSkill}
                  height="210"
                  width="210"
                  title="Sons de Vaca"
                  alt="Sons de Vaca" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.6be1dca2-0b34-49dd-9134-6e9dd6916c15/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Sons de Vaca</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="row six">
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.b141e581-6067-4ec8-9171-32a52e44d36e/launch" target="_blank" rel="noopener noreferrer">
               <img src={UnofficialEasyButtonSkill}
                  height="210"
                  width="210"
                  alt="Botão fácil não oficial"
                  title="Botão fácil não oficial" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.b141e581-6067-4ec8-9171-32a52e44d36e/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Botão fácil não oficial</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.a98be640-3f8e-47a7-a739-5cc75dba05f5/launch" target="_blank" rel="noopener noreferrer">
               <img src={BurpSoundsSkill}
                  height="210"
                  width="210"
                  alt="Sons de Arrotos"
                  title="Sons de Arrotos" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.a98be640-3f8e-47a7-a739-5cc75dba05f5/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Sons de Arrotos</h3>
               </a>
            </div>
         </div>
         <div className="alexa col s12 m4 l4">
            <div className="alexa-image">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.47ee85d4-d6bd-4699-bf3f-2bd26cf79488/launch" target="_blank" rel="noopener noreferrer">
               <img src={DeerSoundsSkill}
                  height="210"
                  width="210"
                  title="Sons de cervos"
                  alt="Sons de cervos" />
               </a>
            </div>
            <div className="alexa-content">
               <a href="https://alexa-skills.amazon.com.br/apis/custom/skills/amzn1.ask.skill.47ee85d4-d6bd-4699-bf3f-2bd26cf79488/launch" target="_blank" rel="noopener noreferrer">
                  <h3 className="center title indigo-text text-darken-4">Sons de cervos</h3>
               </a>
            </div>
         </div>
      </div>
      <div className="container">
        <h2 className="center title">Alexa Skills in other Countries</h2>
        <p className="center">If you live outside of Brazil and are interested in one of my
        Alexa Skills, click on the respective flag from your country to see what
        is available.</p>

        <div className="row one">
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills" rel="noopener noreferrer">
                 <img src={UnitedStates}
                      height="135"
                      width="240"
                      alt="United States"
                      title="United States"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-canada" rel="noopener noreferrer">
                 <img src={Canada}
                      height="135"
                      width="240"
                      alt="Canada"
                      title="Canada"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-united-kingdom" rel="noopener noreferrer">
                 <img src={UnitedKingdom}
                      height="135"
                      width="240"
                      alt="United Kingdom"
                      title="United Kingdom"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                <a href="/alexa-skills-australia" rel="noopener noreferrer">
                  <img src={Australia}
                       height="135"
                       width="240"
                       alt="Australia"
                       title="Australia"
                  />
                </a>
              </div>
           </div>
        </div>
        <div className="row two">
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-mexico" rel="noopener noreferrer">
                 <img src={Mexico}
                      height="135"
                      width="240"
                      alt="Mexico"
                      title="Mexico"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-spain" rel="noopener noreferrer">
                 <img src={Spain}
                      height="135"
                      width="240"
                      alt="Spain"
                      title="Spain"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-france" rel="noopener noreferrer">
                 <img src={France}
                      height="135"
                      width="240"
                      alt="France"
                      title="France"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-germany" rel="noopener noreferrer">
                 <img src={Germany}
                      height="135"
                      width="240"
                      alt="Germany"
                      title="Germany"
                  />
                 </a>
              </div>
           </div>
        </div>
        <div className="row three">
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-italy" rel="noopener noreferrer">
                 <img src={Italy}
                      height="135"
                      width="240"
                      alt="Italy"
                      title="Italy"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-japan" rel="noopener noreferrer">
                 <img src={Japan}
                      height="135"
                      width="240"
                      alt="Japan"
                      title="Japan"
                  />
                 </a>
              </div>
           </div>
           <div className="alexa col s12 m6 l3">
              <div className="flag-image">
                 <a href="/alexa-skills-india" rel="noopener noreferrer">
                 <img src={India}
                      height="135"
                      width="240"
                      alt="India"
                      title="India"
                  />
                 </a>
              </div>
           </div>
        </div>
      </div>
   </div>
   <ContactCTA />
</div>
    )
}

export default AlexaSkillsBrazil;
